import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <main className="e404">
        <h1>404</h1>
        <p>Whoops! Don't know how you got here. Get <Link to="/">back to Work</Link>.</p>
    </main>
  </Layout>
)

export default NotFoundPage
